<template>
  <b-card>
    <validation-observer
      ref="infoRules"
      v-slot="{ invalid }"
      tag="form"
    >
      <b-row>
        <b-col md="6">
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Name"
            rules="required"
          >
            <b-form-group
              label="Name"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="userPanelForm.name"

                :state="getValidationState(validationContext)"
                trim
                placeholder="Name"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Email"
            rules="required"
          >
            <b-form-group
              label="Email"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="userPanelForm.email"
                type="email"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Email"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          v-if="passwordRequired"
          md="6"
        >
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Password"
            rules="required"
          >
            <b-form-group
              label="Password"
              label-for="password"
            >
              <b-form-input
                id="password"
                v-model="userPanelForm.password"
                type="password"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Password"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          v-if="passwordRequired"
          md="6"
        >
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Password Confirmation"
            rules="required"
          >
            <b-form-group
              label="Password Confirmation"
              label-for="password_confirmation"
            >
              <b-form-input
                id="password_confirmation"
                v-model="userPanelForm.password_confirmation"
                type="password"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Password Confirmation"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col md="6">
          <validation-provider
            #default="validationContext"
            rules="required"
            name="role"
          >

            <b-form-group
              label="role "
              label-for="role"
            >
              <b-form-select
                id="role"
                v-model="userPanelForm.role"
                :options="optionsRoles"
                trim
                placeholder="role"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>

          </validation-provider>
        </b-col>
        <b-col
          md="6"
        >
          <validation-provider
            #default="validationContext"
            rules="required"
            name="office"
          >

            <b-form-group
              label="office "
              label-for="office"
            >
              <b-form-select
                id="office_id"
                v-model="userPanelForm.office_id"
                :options="optionsOffice"
                trim
                placeholder="Office Id"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>

          </validation-provider>
        </b-col>

        <!-- Server Errors -->
        <b-col
          v-if="Object.values(errors).length > 0"
          cols="12"
        >
          <p
            v-for="(error, key, index) in errors"
            :key="index"
            class="text-danger small"
          >
            {{ error.toString() }}
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          class="mt-50"
        >

          <b-button
            v-if="!loader && $route.params.id"
            variant="primary"
            class="mr-1"
            :disabled="invalid || Object.values(errors).length > 0"
            @click="addUserPanel()"
          >
            Save Changes
          </b-button>
          <b-button
            v-if="!loader && !$route.params.id"
            variant="primary"
            class="mr-1"
            :disabled="invalid || Object.values(errors).length > 0"
            @click="addUserPanel()"
          >
            Add
          </b-button>
          <b-button
            v-if="loader"
            variant="primary"
            disabled
            class="mr-1"
          >
            <b-spinner
              small
              type="grow"
            />
            Loading...
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </b-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import axios from 'axios'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line no-unused-vars
import _ from 'lodash'
import decryptData from '@/store/auth/decryptData'

export default {
  components: {
  },
  data() {
    return {
      id: this.$store.state.generalIds.id,
      required,
      allSelected: [],
      users: [],
      loader: false,
      passwordRequired: true,
      validationErrors: {},
      errors: {},
      optionsRoles: [],
      optionsOffice: [],
    }
  },

  setup() {
    const { getValidationState } = formValidation()
    // eslint-disable-next-line camelcase

    const userPanelForm = ref({

    })
    return {
      getValidationState,
      userPanelForm,
    }
  },
  watch: {
    'this.userPanelForm.role': function () {
      // Force the reactivity update for the computed property
      this.$forceUpdate()
    },
  },
  mounted() {
    if (this.$route.params.id) {
      this.passwordRequired = false
    }
    this.getRole()
    this.getOffice()
    this.showUserPanel()
  },
  methods: {
    getRole() {
      this.loadtable = true
      axios.get('register/panel/roles').then(res => {
        this.optionsRoles = decryptData(res.data.payload).data.roles.map(item => ({
          value: item.name,
          text: item.name,
        }))
      }).finally(() => {
        this.loadtable = false
      })
    },

    getOffice() {
      this.loadtable = true
      axios.get('office').then(res => {
        this.optionsOffice = decryptData(res.data.payload).data.offices.map(item => ({
          value: item.id,
          text: item.name,
        }))
      }).finally(() => {
        this.loadtable = false
      })
    },
    showUserPanel() {
      if (this.$route.params.id) {
        axios.get(`register/panel/user/${this.$route.params.id}`).then(res => {
          this.userPanelForm = decryptData(res.data.payload).data.user
        })
      } else {
        return false
      }
      return true
    },

    addUserPanel() {
      if (this.$route.params.id) {
        this.loader = true
        const formData = new FormData()
        formData.append('_method', 'PUT')
        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const key in this.userPanelForm) {
          formData.append(key, this.userPanelForm[key])
        }

        axios.post(`register/panel/user/${this.$route.params.id}`, formData).then(res => {
          if (res.status === 200) {
            this.$router.push({ name: 'user-panel' })
            this.$toasted.show('Updated Successfully', {
              position: 'top-center',
              duration: 3000,
            })
          }
        }).catch(error => {
          if (error.response.status) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `${error.response.data.message}`,
                icon: 'BellIcon',
                variant: 'error',
              },
            })
          }
        }).finally(() => {
          this.loader = false
        })
      } else {
        this.loader = true
        const formData = new FormData()
        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const key in this.userPanelForm) {
          formData.append(key, this.userPanelForm[key])
        }

        axios.post('register/panel', formData).then(res => {
          if (res.status === 200 || res.status === 201) {
            this.$router.push({ name: 'user-panel' })
            this.$toasted.show('Created Successfully', {
              position: 'top-center',
              duration: 3000,
            })
          }
        }).catch(error => {
          if (error.response.status) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `${error.response.data.message}`,
                icon: 'BellIcon',
                variant: 'error',
              },
            })
          }
        }).finally(() => {
          this.loader = false
        })
      }
    },
  },

}
</script>

  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  </style>
